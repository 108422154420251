@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Ubuntu';
    src: url('./assets/fonts/Ubuntu-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

body {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'Ubuntu';
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
    outline: none !important;
    /*border: 1px solid black; !*FIXME*!*/
}

a {
    color: inherit;
}

.PhoneInputInput {
    font: inherit;
    height: 48px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    padding: 3px 14px;
}

.PhoneInputInput:disabled {
    opacity: 1 !important;
    color: #999;
    border:  1px solid #ccc;
    background-color: transparent;
    -webkit-text-fill-color: #999;
    -webkit-opacity: 1;
}

.PhoneInputInput:hover {
    border-color: rgba(0, 0, 0, 1);
}

.PhoneInputInput:focus {
    border-width: 2px;
    border-color: rgba(0, 0, 0, 1);
}

.clearfix {
    display: block;
}

.clearfix::after {
    display: block;
    content: ".";
    visibility: hidden;
    height: 0;
    clear: both;
}

.hidden {
    visibility: hidden;
}

.white-space-nowrap {
    white-space: nowrap;
}

.errorBorder > .PhoneInputInput{
    border-color: red;
}